<template>
    <div ref="formWidgetRef" class="widget-select">
        <div class="widget-select-item">
            <label
                v-if="widgetRef.instanceProperties.label"
                class="block font-bold"
                :class="{ 'pointer-event-none': mode == 'edit' }"
                :for="widgetRef.instanceProperties.id"
                >{{ widgetRef.instanceProperties.label }} <span v-if="widgetRef.validationProperties.required">*</span></label
            >
            <div class="flex flex-row">
                <SelectRoot
                    :id="widgetRef.instanceProperties.id"
                    :name="widgetRef.instanceProperties.name"
                    v-model="widgetRef.runtimeProperties.data"
                    class="select-root"
                    :class="{ 'pointer-events-none': mode == 'edit' }"
                    v-model:open="opened"
                >
                    <SelectTrigger class="select-trigger">
                        <SelectValue class="select-value" :placeholder="widgetRef.instanceProperties.placeholder" />
                        <SelectIcon>
                            <img v-if="!opened" src="/assets/icons/chevron-down-triangle.svg" class="icon-normal" />
                            <img v-if="opened" src="/assets/icons/chevron-up-triangle.svg" class="icon-normal" />
                        </SelectIcon>
                    </SelectTrigger>

                    <!-- <SelectPortal class="select-portal"> -->
                    <SelectContent class="select-content" :body-lock="true" position="popper">
                        <SelectScrollUpButton class="select-scrollup-button" />
                        <SelectViewport class="select-viewport">
                            <SelectItem
                                v-for="(option, index) in widgetRef.instanceProperties.options"
                                :key="index"
                                :value="option.label"
                                class="select-item"
                            >
                                <SelectItemText class="select-text">{{ option.label }}</SelectItemText>
                                <SelectItemIndicator class="select-item-indicator">
                                    <img src="/assets/icons/check.svg" class="icon-small" />
                                </SelectItemIndicator>
                            </SelectItem>
                        </SelectViewport>
                        <SelectScrollDownButton class="select-scrolldown-button" />
                    </SelectContent>
                    <!-- </SelectPortal> -->
                </SelectRoot>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useVModel } from '@vueuse/core';

import {
    SelectContent,
    SelectIcon,
    SelectItem,
    SelectItemIndicator,
    SelectItemText,
    SelectRoot,
    SelectScrollDownButton,
    SelectScrollUpButton,
    SelectTrigger,
    SelectValue,
    SelectViewport,
} from 'radix-vue';
// import { useLoggerStore } from '../../../stores/loggerStore';

const props = defineProps({
    mode: {
        type: String,
        default: 'view',
    },
    widget: {
        type: Object,
        required: true,
    },
});
const emit = defineEmits(['update:widget']);

// const { logStatuses } = useLoggerStore();

const widgetRef = useVModel(props, 'widget', emit, { passive: true, deep: true });
const formWidgetRef = ref();

if (widgetRef.value.runtimeProperties.data == null) {
    widgetRef.value.runtimeProperties.data = [widgetRef.value.instanceProperties.min];
}

const opened = ref(false);
</script>

<style lang="postcss" scoped>
.widget-select {
    @apply border-0 h-full w-full;
}
.select-root {
}

.select-trigger {
    @apply font-nubesans inline-flex min-w-[160px] items-center justify-between rounded px-[15px] text-base leading-none;
    @apply h-[35px] gap-[5px] bg-white text-stone-900 shadow-[0_2px_10px] shadow-black/10;
    @apply hover:bg-stone-50 focus:shadow-[0_0_0_2px] focus:shadow-black data-[placeholder]:text-stone-100 outline-none;
}
:deep(.select-content) {
    @apply font-nubesans min-w-[160px] bg-white rounded shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),_0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)];
    @apply will-change-[opacity,transform] data-[side=top]:animate-slideDownAndFade data-[side=right]:animate-slideLeftAndFade data-[side=bottom]:animate-slideUpAndFade data-[side=left]:animate-slideRightAndFade z-[100];
}
.select-scrollup-button {
    @apply flex items-center justify-center h-[25px] bg-white text-stone-900 cursor-default;
}
.select-viewport {
    @apply p-[5px];
}
.select-item {
    @apply text-base leading-none text-stone-900 rounded-[3px] flex items-center h-[25px] pr-[35px] pl-[25px];
    @apply relative select-none data-[disabled]:text-stone-100;
    @apply data-[disabled]:pointer-events-none data-[highlighted]:outline-none data-[highlighted]:bg-sky-500 data-[highlighted]:text-stone-100;
}
.select-item-indicator {
    @apply absolute left-0 w-[25px] inline-flex items-center justify-center;
}
.select-scrolldown-button {
    @apply flex items-center justify-center h-[25px] bg-white text-stone-900 cursor-default;
}
</style>
