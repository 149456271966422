<template>
    <div class="richtext-container" :class="{ focused: isFocused }">
        <QuillEditor
            ref="editorRef"
            :id="editorId"
            theme="snow"
            :toolbar="toolbarOptions"
            contentType="html"
            v-model:content="modelValueRef"
            spellcheck="false"
            @blur="emit('blur')"
        ></QuillEditor>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useVModel } from '@vueuse/core';
import { v4 as uuidv4 } from 'uuid';

import Quill from 'quill';
import { QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

const props = defineProps({
    id: {
        type: String,
        required: true,
    },
    modelValue: {
        type: [String, null],
        required: true,
    },
});

const editorId = uuidv4();

const editorRef = ref();
const toolsbarRef = ref();

const emit = defineEmits(['update:modelValue', 'focus', 'blur']);
const modelValueRef = useVModel(props, 'modelValue', emit);

const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [
        { font: ['Urbanist', 'Roboto', 'NotoSerif', 'Lato', 'Montserrat'] },
        {
            size: [
                '8pt',
                '10pt',
                '12pt',
                '14pt',
                '16pt',
                '18pt',
                '20pt',
                '22pt',
                '24pt',
                '26pt',
                '28pt',
                '30pt',
                '32pt',
                '36pt',
                '40pt',
                '48pt',
                '64pt',
            ],
        },
    ],
    ['clean'], // remove formatting button
];

const listenerClickOutside = (event) => {
    if (event.target === editorRef.value || event.target === toolsbarRef.value) {
        return;
    }
    resetFocused();
};

const resetFocused = () => {
    editorRef.value.getQuill().blur();
    isFocused.value = false;
    window.removeEventListener('click', listenerClickOutside);
};

const setFocused = () => {
    editorRef.value.focus();
    isFocused.value = true;
    window.addEventListener('click', listenerClickOutside);
};
const isFocused = ref(false);
// const toolbarOptions = [['bold', 'italic', 'underline', 'strike'], [{ color: [] }, { background: [] }], [{ font: [] }], ['clean']];

onMounted(() => {
    const FontAttributor = Quill.import('attributors/style/font');
    FontAttributor.whitelist = ['Urbanist', 'Roboto', 'NotoSerif', 'Lato', 'Montserrat'];
    Quill.register(FontAttributor, true);

    const SizeAttributor = Quill.import('attributors/style/size');
    SizeAttributor.whitelist = [
        '8pt',
        '10pt',
        '12pt',
        '14pt',
        '16pt',
        '18pt',
        '20pt',
        '22pt',
        '24pt',
        '26pt',
        '28pt',
        '30pt',
        '32pt',
        '36pt',
        '40pt',
        '48pt',
        '64pt',
    ];
    Quill.register(SizeAttributor, true);

    const BlockPrototype = Quill.import('blots/block');

    class CustomBlock extends BlockPrototype {
        constructor(domNode, value) {
            super(domNode, value);
            this.format('size', '16px');
            this.format('family', 'Urbanist');
            this.format('weight', '400');
            this.format('style', 'normal');
            this.format('line-height', 'normal');
        }

        static tagName = 'P';

        format(name, value) {
            if (name === 'size') {
                this.domNode.style.fontSize = value;
            } else if (name === 'family') {
                this.domNode.style.fontFamily = value;
            } else if (name === 'weight') {
                this.domNode.style.fontWeight = value;
            } else if (name === 'style') {
                this.domNode.style.fontStyle = value;
            } else if (name === 'line-height') {
                this.domNode.style.lineHeight = value;
            } else {
                super.format(name, value);
            }
        }
    }

    Quill.register(CustomBlock, true);
});

defineExpose({
    setFocused,
    resetFocused,
    isFocused,
});
</script>

<style lang="postcss" scoped>
.ql-font-roboto {
    font-family: 'Roboto', sans-serif;
}
.richtext-container {
    @apply h-full overflow-hidden;

    :deep(.ql-editor) {
        @apply p-0 leading-normal;
    }
    :deep(.ql-toolbar) {
        @apply absolute bottom-full w-max  bg-white hidden;
    }
    &.focused :deep(.ql-toolbar) {
        @apply block;
    }
    :deep(.ql-toolbar.ql-snow + .ql-container.ql-snow) {
        border: 0;
    }
    /* make fonts visible in the dropdown in the toolsbar */
    :deep(.ql-snow .ql-picker.ql-font .ql-picker-options .ql-picker-item) {
        font-family: attr(data-value);
        &::before {
            content: attr(data-value) !important;
        }
    }
    :deep(.ql-snow .ql-picker.ql-font .ql-picker-label::before),
    :deep(.ql-snow .ql-picker.ql-font .ql-picker-item::before) {
        content: attr(data-value) !important;
    }
    /* used when applying font to the content */
    :deep(.ql-font-Urbanist) {
        font-family: Urbanist;
    }
    :deep(.ql-font-Roboto) {
        font-family: Roboto;
    }
    :deep(.ql-font-NotoSerif) {
        font-family: NotoSerif;
    }
    :deep(.ql-font-Lato) {
        font-family: Lato;
    }
    :deep(.ql-font-Montserrat) {
        font-family: Montserrat;
    }

    :deep(.ql-snow .ql-picker.ql-size .ql-picker-options .ql-picker-item) {
        &::before {
            content: attr(data-value) !important;
        }
    }
    :deep(.ql-snow .ql-picker.ql-size .ql-picker-label::before),
    :deep(.ql-snow .ql-picker.ql-size .ql-picker-item::before) {
        content: attr(data-value) !important;
    }
}
</style>
