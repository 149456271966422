<template>
    <div ref="formWidgetRef" class="widget-input-number">
        <div class="widget-input-number-item">
            <label
                v-if="widgetRef.instanceProperties.label"
                class="field-label"
                :class="{ 'pointer-event-none': mode == 'edit' }"
                :for="widgetRef.instanceProperties.id"
                >{{ widgetRef.instanceProperties.label }} <span v-if="widgetRef.validationProperties.required">*</span></label
            >
            <div class="flex flex-row">
                <TextFieldEnh
                    :id="widget.instanceProperties.id"
                    :name="widget.instanceProperties.name"
                    label=""
                    :placeholder="widget.instanceProperties.placeholder"
                    inputmode="numeric"
                    v-model:modelValue="widgetRef.runtimeProperties.data"
                    :mode="mode"
                    :class="{ 'pointer-events-none': mode == 'edit' }"
                    :disabled="RulesService.isDisabled(widget)"
                ></TextFieldEnh>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useVModel } from '@vueuse/core';
// import { useLoggerStore } from '../../../stores/loggerStore';
import TextFieldEnh from '../../lib/TextFieldEnh.vue';
import RulesService from '../../../services/RulesService';

const props = defineProps({
    mode: {
        type: String,
        default: 'view',
    },
    widget: {
        type: Object,
        required: true,
    },
});
const emit = defineEmits(['update:widget']);

// const { logStatuses } = useLoggerStore();

const widgetRef = useVModel(props, 'widget', emit, { passive: true, deep: true });
const formWidgetRef = ref();
</script>

<style lang="postcss" scoped>
.widget-input-number {
    @apply border-0;
}
:deep(.field-input-container--disabled) {
    @apply bg-stone-100 border-neutral-normal-light;
}
</style>
