<template>
    <div class="upload-field flex" :disabled="disabled">
        <div class="cursor-pointer" @click="selectFile()">
            <slot :preview="preview"></slot>
            <input ref="fileInput" type="file" class="hidden" :disabled="disabled" :accept="accept" @change="handleFileChange($event)" />
        </div>
        <!-- <div v-if="!noUpload" class="upload-btn relative" :class="{ 'progress progress-striped active': isUploading }">
            <button
                type="button"
                class="flex items-center justify-center bg-slate-700 dark:text-slate-300 dark:bg-blue-700 text-white flex-none py-3 px-8 rounded-full bottom-0 absolute disabled:opacity-70 disabled:cursor-not-allowed"
                :class="{ 'progress-bar cursor-wait': isUploading }"
                @click="uploadingFunction"
                :disabled="!file.previewUrl"
            >
                {{ isUploading ? progressBtnText : uploadBtnText }}
            </button>
        </div> -->
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useVModel } from '@vueuse/core';

const props = defineProps({
    accept: {
        type: String,
    },
    noUpload: {
        type: Boolean,
        default: true,
    },
    uploadBtnText: {
        type: String,
        default: 'Upload',
    },
    progressBtnText: {
        type: String,
        default: 'Uploading...',
    },
    uploadedFile: {
        type: [File, String, null],
        required: true,
    },
    callback: {
        type: Function,
        required: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update:uploadedFile']);

const imageRef = useVModel(props, 'uploadedFile', emit);

const isUploading = ref(false);

const fileInput = ref();
const preview = ref({});
// const fileObj = ref({});

onMounted(() => {});

// const uploadingFunction = async () => {
//     isUploading.value = true;
//     await props.callback(fileObj.value);

//     file.value = {};

//     if (props.uploadedFile) {
//         file.value.previewType = props.uploadedFile.fileType;
//         file.value.previewUrl = props.uploadedFile.fileUrl;
//         file.value.previewName = props.uploadedFile.fileName;
//         fileObj.value = props.uploadedFile;
//     }

//     isUploading.value = false;
// };

const selectFile = () => {
    if (isUploading.value) {
        return;
    }
    const fileInputRef = fileInput.value;
    if (fileInputRef) {
        fileInputRef.click();
    }
};

const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
        // previewFile(file);
        imageRef.value = file;
    }
};

// const previewFile = (fileToPreview) => {
//     const reader = new FileReader();
//     reader.onload = () => {
//         preview.value = reader.result;
//     };
//     reader.onerror = (error) => {
//         console.error(`Error reading file ${fileToPreview.name}: ${error}`);
//     };
//     reader.readAsDataURL(fileToPreview);
// };
</script>
